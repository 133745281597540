<script setup>
import { inject, computed } from "vue";

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
});

const selectedTab = inject("selectedTab");

const isVisible = computed(() => selectedTab.value === props.value);
</script>

<template>
  <div v-if="isVisible">
    <slot />
  </div>
</template>
