<template>
    <div>
        <Lead style="height: calc(100vh - 50px); margin-top: 25px !important;"></Lead>
    </div>

</template>

<script>
import BaseView from '@/template/BaseView.vue';
import Lead from '@/components/LeadUnico/LeadUnico.vue';

export default {
  components: {
    BaseView,
    Lead
  }
}
</script>