import Rest from '@/services/Rest';

/**
 * @typedef {SellerAutomation}
 */
export default class SellerAutomation extends Rest {
    /**
     * @type {String}
     */
    static resource = 'user/automation'


}