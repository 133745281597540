import Rest from '@/services/Rest';

/**
 * @typedef {NotaService}
 */
export default class NotaService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'note'


}
