<template>
  <div class="body">
    <div class="day-mark" v-if="fixedText" style="position: absolute; top: 0px; z-index: 300;"><span>{{ lead && lead.id ? fixedText : 'Selecione um lead para visualizar as mensagens'}}</span></div>
    <div style="padding: 20px; max-height: 100%; overflow-y: auto; overflow-x: hidden; min-height: 100%; " v-if="!loading"  ref="bodyMessages" @scroll="debounceDay">
      <div class="days" v-if="this.days.length" style="height: 10px; width: 100%;"></div>
      <div v-for="(day, index) in days" :key="index" class="days trueDays" :class="{'firstDay' : index == 0, 'secondDay' : index == 1 }">
        <div class="day-mark" v-if="day.messages && day.messages[0]"><span>{{ myDateLog(day.messages[0].date) }}</span></div>
        <div v-for="(log, index) in day.messages" :key="index" class="containerMessage">
          <div :class="log.type == 'message-in' ? 'container-other-message' : 'container-my-message'">
            <div class="message" v-if="log.type_message == 'chat'">
              <p style="white-space: pre-line">
                {{ log.message && log.message.length > 768 ? log.message.slice(0, -9) : log.message }}
                <span v-if="log.message && log.message.length > 768" style="color: #0004; display: inline-block;"> 
                  <svg xmlns="http://www.w3.org/2000/svg" style="margin-top: -2px;" width="20" height="20" fill="#0004" viewBox="0 0 256 256"><path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path></svg>
                  Vizualize a mensagem completa na conversa original
                </span>
              </p>
              <p>
                {{log.date.slice(-8).slice(0, 5)}}
              </p>
            </div>
            <audio
              controlsList="nodownload"
              v-if="log.type_message == 'audio'"
              controls
              class="message-audio"
            >
              <source :src="log.filepath" type="audio/ogg" />
              <span class="log-time">{{log.date.slice(-8).slice(0, 5)}}</span>
            </audio>
            <div
              v-if="log.type_message.includes('image')"
              class="message-image"
            >
              <span class="log-time" :style="log.type == 'message-in' && log.message.length ? 'color: var(--grey03); text-shadow: none;' : ''">{{log.date.slice(-8).slice(0, 5)}}</span>

              <img :src="log.filepath" alt="Imagem" />
              <p v-if="log.message.length">
                {{ log.message.length > 768 ? log.message.slice(0, -9) : log.message }}
                <span v-if="log.message.length > 768" style="color: #0004; display: inline-block;"> 
                  <svg xmlns="http://www.w3.org/2000/svg" style="margin-top: -2px;" width="20" height="20" fill="#0004" viewBox="0 0 256 256"><path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path></svg>
                  Vizualize a mensagem completa na conversa original
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="justify-content-center style containerx">
      <svg xmlns="http://www.w3.org/2000/svg" style="opacity: 0.5;" width="150" height="150" fill="#4ea934" viewBox="0 0 256 256"><path d="M128,32A96,96,0,0,0,44.89,176.07L32.42,213.46a8,8,0,0,0,10.12,10.12l37.39-12.47A96,96,0,1,0,128,32Zm24,152a80,80,0,0,1-80-80,32,32,0,0,1,32-32l16,32-12.32,18.47a48.19,48.19,0,0,0,25.85,25.85L152,136l32,16A32,32,0,0,1,152,184Z" opacity="0.2"></path><path d="M187.58,144.84l-32-16a8,8,0,0,0-8,.5l-14.69,9.8a40.55,40.55,0,0,1-16-16l9.8-14.69a8,8,0,0,0,.5-8l-16-32A8,8,0,0,0,104,64a40,40,0,0,0-40,40,88.1,88.1,0,0,0,88,88,40,40,0,0,0,40-40A8,8,0,0,0,187.58,144.84ZM152,176a72.08,72.08,0,0,1-72-72A24,24,0,0,1,99.29,80.46l11.48,23L101,118a8,8,0,0,0-.73,7.51,56.47,56.47,0,0,0,30.15,30.15A8,8,0,0,0,138,155l14.62-9.74,23,11.48A24,24,0,0,1,152,176ZM128,24A104,104,0,0,0,36.18,176.88L24.83,210.93a16,16,0,0,0,20.24,20.24l34.05-11.35A104,104,0,1,0,128,24Zm0,192a87.87,87.87,0,0,1-44.06-11.81,8,8,0,0,0-6.54-.67L40,216,52.47,178.6a8,8,0,0,0-.66-6.54A88,88,0,1,1,128,216Z"></path></svg>
    </div>
  </div>
</template>
<script>
import LeadMessagesService from "@/services/resources/LeadMessageService";
const LogMessages = LeadMessagesService.build();
import moment from "moment";
import _ from "lodash";

export default {
  name: "WhatsAppMessages",
  props: ["lead", "seller", "fromLead"],
  data() {
    return {
      loading: true,
      messagePage: 1,
      days: [],
      fixedText: '',
      blockMessages: false,
      wait: false
    };
  },
  watch: {
    'lead.id': function (newValue) {
      this.messagePage = 1
      this.days = []
      this.loading = true
      this.fixedText = 'Carregando...'
      this.stop = false
      this.wait = false
      newValue ? this.searchMessage() : ''
    },
    'seller.id': function (newValue) {
      if (this.fromLead) {
        this.messagePage = 1
        this.days = []
        this.loading = true
        this.fixedText = 'Carregando...'
        this.stop = false
        this.wait = false
        newValue ? this.searchMessage() : ''
      }
    },
  },
  methods: {
    debounceMessage: _.debounce(function () {
      if (this.stop) {
        this.fixedText = 'Não há mais mensagens'
        return
      }
      this.searchMessage();
    }, 1000),
    debounceDay: _.debounce(function () {
      this.handleScroll();
    }, 100),
    handleScroll(){
      const container = this.$refs.bodyMessages;
      const days = container.querySelectorAll(".days");
      const containerRect = container.getBoundingClientRect();
      let day = Array.from(days).find((day) => {
        const dayRect = day.getBoundingClientRect();
        return containerRect.top <= dayRect.top + day.offsetHeight ;
      });
      if (day && day.querySelector("span")) {
        this.fixedText = day.querySelector("span").textContent
      } else if (this.wait) {
        this.debounceMessage()
      }
    },
    initScroll(){
      const div = this.$refs.bodyMessages;
      div.scrollTop = 999999;
    },
    async searchMessage(init) {
      this.fixedText = 'Carregando mais mensagens...'
      let date1 = "2000/01/01 00:00:00";
      let date2 = moment().add(1, 'day').startOf('day').format("YYYY/MM/DD HH:mm:ss");
      let data = {
        page: this.messagePage,
        id: `/search`,
        startDate: date1,
        endDate: date2,
        user_id: this.seller.id,
        per_page: 2,
        lead_id: this.lead.id
      };

      LogMessages.createId(data)
        .then((response) => {
          if (response.current_page == response.last_page) {
            this.stop = true
          }
          let filteredData = response.data.filter((day) => day.messages.length).reverse()
          this.messagePage++
          if (!filteredData.length) {
            this.fixedText = 'Não há mais mensagens'
          }
          if (filteredData && filteredData.length) {
            this.fixedText = ''
            this.days = filteredData.concat(this.days)
            this.loading = false;
            this.$nextTick(() => {
              if (this.messagePage == 2 || init) {
                this.handleScroll()
                this.initScroll()
                this.$nextTick(() => {
                  this.wait = true
                });
                const container = this.$refs.bodyMessages;
                if (container.scrollHeight <= container.clientHeight && response.current_page != response.last_page) {
                  this.searchMessage(init)
                }
              } else {
                const container = this.$refs.bodyMessages;
                const first = container.querySelector(".firstDay")
                const second = container.querySelector(".secondDay")
                if (filteredData.length == 1) {
                  container.scrollTop = (first.offsetHeight)
                } else {
                  container.scrollTop = (second.offsetHeight + first.offsetHeight)
                }
              }
            });
          } else if (response.current_page != response.last_page) {
            this.searchMessage()
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    myDateLog(date) {
      const formatDate = moment().format("DD/MM/YYYY");
      const dateFormat = moment(date).format("DD/MM/YYYY");

      if (formatDate === dateFormat) {
        return `Hoje`;
      } else {
        const currentDate = moment().startOf('day');
        const dateMsg = moment(date).startOf('day');
        const diff = currentDate.diff(dateMsg, "days");
        if (diff === 1) {
          return `Ontem`;
        } else {
          return dateMsg.format(`DD/MM/YYYY`);
        }
      }
    },
  },
  mounted() {
    if (this.fromLead && this.lead && this.lead.id && this.seller && this.seller.id) {
      this.searchMessage(true)
    }
  },
};
</script>

<style lang="scss" scoped>
.day-mark {
  width: 100%;
  padding: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 10px;
    color: var(--greenn);
    background-color: var(--greenn2);
  }
}
.log-time {
  font-size: 12px;
  color: white;
  position: absolute;
  bottom: 5px;
  right: 12px;
  text-shadow: 0px 0px 10px black, 0px 0px 15px black;
}
.containerx {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

audio::-webkit-media-controls-volume-slider,
audio::-webkit-media-controls-mute-button,
audio::-webkit-media-controls-volume-control-container {
  display: none !important;
}

audio::-webkit-media-controls-enclosure {
  content: none !important;
}

/* Remover o botão de menu (três pontinhos) */
audio::-webkit-media-controls-overflow-button {
  display: none !important;
}

audio {
  background-color: #ff6b77;
  box-shadow: 1px 6px 11px 2px #65656514;
  border-radius: 15px;
  padding: 10px;
  filter: invert(1);
}

/* Estilizando os controles do componente de áudio */
audio::-webkit-media-controls-panel {
  background-color: #ff6b77;
  border-radius: 5px;
  padding: 5px;
}

/* Estilizando os botões de controle */
audio::-webkit-media-controls-start-button,
audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-pause-button,
audio::-webkit-media-controls-mute-button,
audio::-webkit-media-controls-fullscreen-button {
  color: #fff;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/* Estilizando a barra de progresso da linha do tempo */
audio::-webkit-media-controls-timeline {
  height: 5px;
  border-radius: 5px;
}

/* Estilizando a barra de progresso preenchida */
audio::-webkit-media-controls-timeline::-webkit-media-slider-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

/* Estilizando o tempo atual e restante */
audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
  font-size: 13px;
}

/* Estilizando a barra de volume */
audio::-webkit-media-controls-volume-slider {
  height: 5px !important;
  border-radius: 5px;
}

/* Estilizando o controle de volume preenchido */
audio::-webkit-media-controls-volume-slider::-webkit-media-slider-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.body {
  background-image: url("../../../assets/img/bgLogMessage.png");
  border-left: 1px solid #dbdbdb;
  background-color: #f5f5f5;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
  .containerMessage {
    margin-top: 5px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: flex-start;
    .message {
      max-width: 60%;
      padding: 6px 9px 6px 15px;
      p,
      a {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0 !important;
        display: contents;
      }
      p:last-child {
        font-size: 10px;
        font-weight: 400;
        line-height: 1;
        text-align: right;
        margin-right: -8px;
        margin-bottom: -4px;
      }
    }

    .container-my-message {
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      // .seqMyMessage {
      //   // background-color: purple !important;
      //   margin-top: -8px !important;
      //   border-radius: 30px 5px 5px 30px !important;
      // }
      // .seqMyMessageOne {
      //   // background-color: red !important;
      //   border-radius: 30px 30px 0px 30px !important;
      // }

      .message {
        align-self: flex-end;
        border-radius: 4px;
        box-shadow: 1px 6px 11px 2px #65656514;
        border-radius: 10px;
        background-color: var(--greenn);
        p {
          color: #fff;
        }

        a {
          color: #fff;
        }
      }

      .message-audio {
        align-self: flex-end;
        box-shadow: 1px 6px 11px 2px #65656541;
      }

      .message-image {
        align-self: flex-end;
        width: 350px;
        max-width: 80%;
        position: relative;
        overflow: hidden;
        background-color: var(--greenn);
        padding: 3px;
        border-radius: 10px;
        box-shadow: 1px 6px 11px 2px #65656541;

        img {
          width: 100%;
          object-fit: cover;
          border-radius: 10px;
        }

        p {
          color: #fff;
          padding: 7px 10px 7px 10px;
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 0px;
        }
      }
    }

    .container-other-message {
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;

      .message {
        align-self: flex-start;
        border-radius: 4px;
        box-shadow: 1px 6px 11px 2px #65656514;
        border-radius: 10px;
        background-color: #fff;
        overflow: hidden;
      }

      .message-audio {
        align-self: flex-start;
        background-color: #fff;
        filter: invert(0);
        &::-webkit-media-controls-panel {
          background-color: #fff;
        }
      }

      .message-image {
        align-self: flex-start;
        width: 350px;
        max-width: 80%;
        overflow: hidden;
        position: relative;
        background-color: #fff;
        padding: 3px;
        border-radius: 10px;
        box-shadow: 1px 6px 11px 2px #65656533;

        img {
          width: 100%;
          object-fit: cover;
          border-radius: 5px;
        }

        p {
          color: #000;
          padding: 7px 10px 7px 10px;
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
