<template>
  <section class="view-address">
    <div @click="openAddress" style="cursor: pointer">
      <p class="title">Endereço</p>
      <img
        src="@/assets/icons/caret-up.svg"
        alt="arrow"
        :style="{ transform: showAddress ? 'rotate(0deg)' : 'rotate(180deg)' }"
      />
    </div>

    <div
      class="card"
      :class="{
        expandedAddress: showAddress,
        collapsedAddress: !showAddress,
      }"
    >
      <div v-for="(field, index) in addressFields" :key="index">
        <p>{{ field.label }}</p>
        <span>{{ field.value ? field.value : "Sem registro" }}</span>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, defineProps, computed } from "vue";

const props = defineProps({
  lead: {
    type: Object,
    require: true,
  },
});

const showAddress = ref(false);

const addressFields = computed(() => {
  const { address } = props.lead;

  return [
    { label: "CEP", value: address?.zip_code },
    { label: "Estado", value: address?.state },
    { label: "Cidade", value: address?.city },
    { label: "Logradouro", value: address?.street },
    { label: "Bairro", value: address?.neighborhood },
    { label: "Número", value: address?.number },
  ];
});

function openAddress() {
  showAddress.value = !showAddress.value;
}
</script>

<style scoped lang="scss">
.view-address {
  margin-top: 6px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      margin-left: 70px !important;
      font-size: 14px;

      color: var(--greenn);
      font-weight: 600;
      text-transform: uppercase;
      user-select: none;
      -webkit-user-drag: none;
    }

    img {
      transition: all 0.3s;
    }
  }

  .expandedAddress {
    max-height: 365px;
  }

  .collapsedAddress {
    max-height: 0;
    border-color: transparent !important;
  }

  .card {
    margin-top: 10px;
    width: 100%;
    border-radius: 12px;
    border: 0.5px solid var(--greenn);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);

    overflow: hidden;
    transition: 0.3s;

    padding: 8px 0;

    div {
      display: grid;
      gap: 2px;
      padding: 4px 16px;

      p {
        font-weight: 600;
        font-size: 14px;
        color: var(--gray02);
      }

      span {
        font-size: 14px;
        color: var(--gray05);
      }
    }

    div:nth-child(3),
    div:nth-child(4) {
      grid-column: span 2;
    }

    div:nth-child(2),
    div:nth-child(6) {
      justify-self: end;
    }

    div:nth-child(5) {
      grid-row-start: 4;
    }
  }
}
</style>
