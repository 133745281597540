import Rest from '@/services/Rest';

/**
 * @typedef {GoogleService}
 */
export default class GoogleService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'google'


}