import Rest from "@/services/Rest";

/**
 * @typedef {Activitie}
 */
export default class Activitie extends Rest {
  /**
   * @type {String}
   */
  static resource = "activitie";
}
