<script setup>
import { getCurrentInstance, ref, defineProps, computed } from "vue";
import { mapGetters } from "vuex";

const vm = getCurrentInstance();

const props = defineProps({
  midias: {
    type: Array|Object,
    default: () => [],
  },
});
// variables
const midiaSelected = ref({ url: "", name: "Selecione uma url" });
const isPlaying = ref(false);

// functions
function getMidias() {
  isPlaying.value = false;
  midiaSelected.value = props.midias[0] ?? props.midias;
}

function selectMidia(event) {
  midiaSelected.value = event;
}

function playAudio() {
  let audioPlayer = vm.proxy.$el.getElementById("audioPlayer");

  if (audioPlayer) {
    if (isPlaying.value) {
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
    } else {
      audioPlayer.play();
    }
    isPlaying.value = !isPlaying.value;
  }
}

// computeds
const getType = computed(() => {
  const type = midiaSelected.value.type ?? "";

  if (type.includes("image/")) return "image";
  if (type.includes("audio/")) return "audio";
  if (type.includes("application/pdf")) return "pdf";
  if (type.includes("video/")) return "video";
  if (type !== "") return "doc";

  return "";
});
</script>

<template>
  <b-modal
    id="View-midia"
    title="Visualizar aquivo"
    centered
    hide-footer
    scrollable
    size="lg"
    @shown="getMidias"
  >
    <div class="content-image">
      <div v-if="midias && midias.length > 1">
        <label for="select-file">Selecione um arquivo</label>
        <BaseSelect
          id="select-file"
          :selectModel="midiaSelected"
          :array="midias"
          trackid="url"
          trackname="name"
          selectLabel=""
          deselectLabel=""
          :allowEmpty="false"
          :watch="true"
          @change="selectMidia($event)"
        />
      </div>
      <img v-if="getType == 'image'" :src="midiaSelected.url" />

      <div v-if="getType === 'audio'" class="centered-container">
        <img
          src="https://app.greenn.club/img/audio.f8442356.png"
          alt="Play Audio"
          @click="playAudio"
          style="width: 35%"
          :class="'audio-image ' + (isPlaying ? 'started' : '')"
        />
        <audio
          controlsList="nodownload"
          style="width: 100%;display: none;"
          controls
          id="audioPlayer"
        >
          <source :src="midiaSelected.url" :type="midiaSelected.type" />
        </audio>
        <span @click="playAudio">Clique para reproduzir</span>
      </div>

      <video v-else-if="getType == 'video'" controls>
        <source :src="midiaSelected.url" :type="midiaSelected.type" />
      </video>

      <embed
        v-else-if="getType == 'pdf'"
        :src="midiaSelected.url"
        :type="midiaSelected.type"
        style="width: 100%; height: 500px"
      />

      <div v-else-if="getType === 'doc'" class="download-file">
        <div class="file">
          <img src="@/assets/icons/file.svg" alt="" />
          <a
            :href="midiaSelected.url"
            :download="midiaSelected.name"
            class="download-link"
          >
            {{ midiaSelected.name }}
            <div class="download">
              <img src="@/assets/icons/download.svg" alt="">
            </div>
          </a>
        </div>
      </div>

    </div>
  </b-modal>
</template>

<style>
.content-image {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  height: 500px;
  border-radius: 10px;
  border: 1px solid var(--gray-100);
  display: flex;
  flex-direction: column;
  justify-content:center;
  img{
    max-height: 100%;
    object-fit: contain;
  }
}

.download-file {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
  background-color: var(--gray-white);
}
.file{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border: 2px solid #e5e7e7b0;
  border-radius: 10px;
  background: #f0f2f56b;
  a{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .download{
    border-radius: 50%;
    border: 1px dashed var(--gray-100);
    width: 2em;
    height: 2em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.audio-image {
  cursor: pointer;
  max-width: 100%;
  height: auto; 
}
.centered-container {
  display: flex;
  justify-items: center;
  gap: 30px;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  span {
    color:var(--greenn);
    font-weight: bold;
    cursor: pointer;
  }
}
.started{
  filter: contrast(150%);
}
</style>
