<script setup>
import { provide, ref, watch } from "vue";

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const selectedTab = ref(props.modelValue);

const setSelectedTab = (newValue) => {
  selectedTab.value = newValue;
  emit("update:modelValue", newValue);
};

provide("selectedTab", selectedTab);
provide("setSelectedTab", setSelectedTab);

watch(
  () => props.modelValue,
  (newVal) => {
    selectedTab.value = newVal;
  }
);
</script>

<template>
  <div class="container-tabs">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.container-tabs {
  display: grid;
  gap: 20px;
}
</style>
