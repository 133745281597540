<template>
    <b-modal name="modal-situation" id="modal-situation" idModal="modal-situation" size="md"
        title="Verifique o remetente" :hide-header="true" :hide-footer="true" centered>
        <div class="container-flex">
            <h2>Lead</h2>
            <p>Como deseja definir o lead?</p>
        </div>
        <div style="display: flex; gap: 15px; justify-content: center;">
            <BaseButton @click="emitSituation('lossModal')" style="padding: 0 28px!important;" variant="danger">
                Perdido
            </BaseButton>

            <BaseButton @click="emitSituation('gainModal')" style="padding: 0 28px!important;" variant="primary">
                Ganho
            </BaseButton>
        </div>
    </b-modal>
</template>

<script> 
export default {
    methods: {
        emitSituation(situation) {
            this.$bvModal.hide('modal-situation');
            this.$emit(situation);
        }
    }
};
</script>

<style lang="scss" scoped>
.container-flex {
    display: grid;
    justify-items: center;
    text-align: center;
    margin-bottom: 20px;

    h2 {
        margin: 0 0 0.4em;
        padding: 0;
        color: #595959;
        font-size: 1.875em;
        font-weight: 600;
        text-align: center;
        text-transform: none;
        word-wrap: break-word;
        font-size: 20px !important;
        margin-bottom: 20px !important;
    }

    p {
        color: var(--gray03);
        font-size: 16px;
        line-height: 1.5;
        font-size: 16px;
    }

    span {
        font-weight: 600;
        font-size: 20px;
    }
}
</style>