import Rest from '@/services/Rest';

/**
 * @typedef {WhatsappServiceNew}
 */
export default class WhatsappServiceNew extends Rest {
    /**
     * @type {String}
     */
    static resource = 'wppNew'
    
}