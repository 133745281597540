
import Rest from '@/services/Rest';

/**
 * @typedef {ConfigProdutosService}
 */
export default class ConfigProdutosService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'settings/product'


}