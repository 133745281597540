<template>
  <div
    class="product-card"
    style="grid-template-columns: calc(100% - 35px) 20px"
    >
    <div class="product-infos">
      <div
        class="card-container"
        style="
          display: flex;
          justify-content: space-between;
          gap: 10px;">          
        <p :class="{ 'item-meta': !seeMoreButton , 'item-meta-expanded':  seeMoreButton }">
          {{  item.meta_key  }}
        </p>      
        <span
          style="
            color: var(--gray05) !important;
            font-size: smaller;
            display: inline;
            min-width: fit-content;
          "
          >{{ formatDate(item.updated_at) }}
        </span>
      </div>
      <div :class="['card-container', { 'row-direction':  seeMoreButton }]">
        <p :class="{ 'item-meta': !seeMoreButton, 'item-meta-expanded':  seeMoreButton }"
          :ref="'metaValue'"
          :style="{ color: '#81858e !important' }">
          {{ item.meta_value }}
        </p>                                
        <span @click="toggleSeeMore(index)" class="see-more-buttom" v-if="showSeeMoreButton">
          {{ seeMoreButton ? "Ver menos" : "Ver mais" }}
        </span> 
      </div>    
    </div>
    <div>
      <img
        @click="deleteEmit(item.id)"
        src="@/assets/img/icons/trash2.svg"
        class="x cursor"
        style="
          margin: 0 0px 10px 0 !important;
          padding: 0 3px 0 0 !important;
        "
      />
      <div style="position: relative">
        <img
          style="
            margin: 0 !important;
            padding: 0 !important;
            height: 16px !important;
          "
          src="@/assets/icons/edit.svg"
          class="cursor x"
          @click.stop="
            editAdd.name = item.meta_key;
            editAdd.value = item.meta_value;
            const newModal = currentModal === `adicional-collapse-${item.id}` ? '' : `adicional-collapse-${item.id}`;
            $emit('updateModal', newModal);
          "
        />
        <div
          v-if="
            currentModal == `adicional-collapse-${item.id}`
          "
          class="drop-top drop-right"
          @click.stop="&quot;&quot;;"
          >
          <input
            type="text"
            placeholder="Nome do campo"
            v-model="editAdd.name"
          />
          <input
            type="text"
            placeholder="Valor do campo"
            v-model="editAdd.value"
          />      
          <div
            @click="
              saveAddEmit(item.id, editAdd.name, editAdd.value);             
            "
            class="cursor btn-follow w-100"
            style="text-align: center"
            >
            Salvar campo adicional
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment-timezone";
import AdicionalService from "@/services/resources/AdicionalService";
const serviceAdicional = AdicionalService.build();
export default {
  props: {
    item: Object,
    index: Number,
    widthMax: Number,
    currentModal: String,
  },
  data() {
    return {     
      showSeeMoreButton: true, 
      seeMoreButton: false,   
      editAdd: {
        name: "",
        value: "",
      },     
    };
  },
  filters: { 
    moment: function (data) {
      return moment(data).format("hh:mm");
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.showSeeMore();
    }); 
  },
  watch: { 
    'item.meta_value': function(newValue, oldValue) {
      this.$nextTick(() => {
        this.showSeeMore();
      });
    }
  },
  methods: {
    deleteEmit(id){   
      this.$emit("deleteAdd", id);    
    },
    saveAddEmit(id, name, value){  
      this.$emit("saveAdd", id, name, value); 
      this.$emit("updateModal", ''); 
    }, 
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },    
    showSeeMore() { 
      const cardWidthMax = this.widthMax;    
      const metaValueWidth = this.$refs.metaValue.offsetWidth + 52;   
      if (cardWidthMax  > metaValueWidth) {          
        this.showSeeMoreButton = false
      } else {
        this.showSeeMoreButton = true
      }    
    },
    toggleSeeMore() {    
      this.seeMoreButton = !this.seeMoreButton;      
    }, 
  },
}
</script>
<style lang="scss" scoped>

.product-card {
  display: grid;
  grid-template-columns: 47px calc(100% - 97px) 20px;
  gap: 15px;
  align-items: center;
  height: fit-content;  
}
p {
  color: var(--gray02);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  letter-spacing: 0;
} 
.row-direction {
  flex-direction: column !important; 
}
.card-container {  
  display: flex;
  flex-direction: row;
}
.see-more-buttom{ 
  white-space: nowrap !important;
  overflow: visible !important;
  text-overflow: clip !important; 
  color: var(--greenn) !important; 
  width: 80px;
  font-size: 12px !important;  
  cursor: pointer;
  text-transform: lowercase !important;  
}
.item-meta {
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}
.item-meta-expanded {
  white-space: normal !important; 
  overflow: visible !important;
  word-wrap: break-word; 
  display: block; 
  width: calc(100% - 80px);
}
.cursor {
  cursor: pointer;
}
.btn-follow {
  margin-top: 0;
  font-weight: bold;
  transition: 0.5s ease;
  background: #e5f9f6;
  color: var(--greenn);
  text-transform: uppercase;
  font-size: 12px;
  padding: 8px !important;
  border-radius: 8px;
  border: none;
  height: 40px;
  transition: all 0.3s;
  text-align: center;
}
.btn-follow:hover {
  background-color: var(--greenn-transparent);
}
.drop-bottom,
.drop-top {
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding: 15px;
  width: 285px;
  position: absolute;
  box-shadow: 2px 2px 10px 1px var(--white-medium);
  background-color: var(--white);
  z-index: 2;
  left: 0;
  margin: 0;
  user-select: none;
}
.drop-right {
  right: 0 !important;
  left: unset !important;
  transform: unset;
}
</style>