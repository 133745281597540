<script setup>
import { Tabs, TabsContent, TabsHeader, TabsTrigger } from "@/components/_defaults/tabs";
import { watch, ref } from "vue";

const props = defineProps({
  purchaseDetails: {
    type: Array,
    required: true,
  },
});

const tabSelected = ref("all");
const listPurchaseDetails = ref([]);

function getIcon(type) {
  const iconMap = {
    email: require("@/assets/img/icons/checkout-email.svg"),
    address: require("@/assets/img/icons/checkout-address.svg"),
    cellphone: require("@/assets/img/icons/checkout-phone.svg"),
    credit_card: require("@/assets/img/icons/checkout-card.svg"),
    boleto: require("@/assets/img/icons/checkout-card.svg"),
    pix: require("@/assets/img/icons/checkout-card.svg"),
  };

  return iconMap[type];
}

function getType(type) {
  const typeMap = {
    email: "E-mail",
    address: "Endereço",
    cellphone: "Telefone",
    credit_card: "Método de pagamento",
    boleto: "Método de pagamento",
    pix: "Método de pagamento",
  };

  return typeMap[type] || type;
}

function getAddress(address) {
  const { street, number, neighborhood, city, state, zip_code, test } = address;

  const parts = [
    street ? `${city}, ${number}` : number,
    number,
    neighborhood,
    city ? `${city} - ${state}` : state,
    zip_code,
    test,
  ].filter(Boolean);

  return parts.join(" | ");
}

function formatValue(item) {
  switch (item.type) {
    case "credit_card":
      return "Cartão de crédito";
    case "boleto":
      return "Boleto";
    case "pix":
      return "PIX";
    case "address":
      return getAddress(item.value);
    default:
      return item.value;
  }
}

function changeTab(tab) {
  tabSelected.value = tab;
  getData();
}

async function getData() {
  if (tabSelected.value === "all") {
    listPurchaseDetails.value = props.purchaseDetails;
  } else if (tabSelected.value === "payment") {
    listPurchaseDetails.value = props.purchaseDetails.filter((item) =>
      ["credit_card", "pix", "boleto"].includes(item.type)
    );
  } else {
    listPurchaseDetails.value = props.purchaseDetails.filter(
      (item) => item.type === tabSelected.value
    );
  }
}

watch(
  () => props.purchaseDetails,
  (newVal) => {
    if (newVal) {
      getData();
    }
  }
);
</script>

<template>
  <section>
    <h4 class="title">DADOS DE COMPRAS REALIZADAS</h4>

    <Tabs :modelValue="tabSelected" @update:modelValue="changeTab">
      <TabsHeader>
        <TabsTrigger value="all"> Todos </TabsTrigger>
        <TabsTrigger value="email"> E-mail </TabsTrigger>
        <TabsTrigger value="cellphone"> Telefone </TabsTrigger>
        <TabsTrigger value="address"> Endereço </TabsTrigger>
        <TabsTrigger value="payment"> Método de pagamento </TabsTrigger>
      </TabsHeader>

      <h5 class="sub-title">Dados da compra</h5>

      <TabsContent :value="tabSelected">
        <div
          v-if="listPurchaseDetails.length > 0"
          v-for="(item, index) in listPurchaseDetails"
          :key="index"
          class="container-data"
        >
          <div class="container-type">
            <div class="icon">
              <img :src="getIcon(item.type)" :alt="getType(item.type)" />
            </div>

            <div>
              <span> {{ getType(item.type) }} </span>
              <p>
                {{ formatValue(item) }}
              </p>
            </div>
          </div>

          <div>
            <div class="count" v-b-tooltip.hover :title="`Utilizado em ${item.count} compras`">
              {{ item.count }}
            </div>
          </div>
        </div>

        <div v-if="listPurchaseDetails.length === 0" class="empty">
          <span> Sem registros. </span>
        </div>
      </TabsContent>
    </Tabs>
  </section>
</template>

<style scoped lang="scss">
section {
  width: 100%;
  height: 100%;

  padding: 25px 25px 0;
  overflow-y: scroll;

  h5,
  h4 {
    margin: 0 !important;
  }

  .title {
    color: var(--greenn);
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;

    margin-bottom: 20px !important;
  }

  .sub-title {
    color: var(--gray05);
    font-weight: 500;
    font-size: 13px;
  }

  .container-data {
    height: 80px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 12px;
    margin-bottom: 20px;

    border-radius: 8px;
    border: 1px solid var(--white-medium);

    .container-type {
      display: flex;
      gap: 16px;
      align-items: center;

      .icon {
        border-radius: 999px;
        height: 56px;
        width: 56px;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: var(--white-light);

        img {
          width: 23px;
        }
      }

      div {
        display: grid;
        max-width: 90%;

        span,
        p {
          overflow: hidden;
          text-wrap: nowrap;
          text-overflow: ellipsis;
        }

        span {
          color: var(--gray05);
          font-size: 14px;
          font-weight: 500;
        }

        p {
          color: var(--gray02);
          font-weight: 600;
          font-size: 14px;
        }
      }
    }

    .count {
      border-radius: 999px;
      height: 36px;
      width: 36px;

      background-color: var(--greenn2);
      color: var(--greenn);
      font-weight: 600;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .empty {
    display: grid;
    place-content: center;
    margin-top: 70px;

    span {
      font-size: 14px;
      color: var(--gray05);
    }
  }
}
</style>
