<script setup>
import { inject, ref, onMounted, nextTick, watch } from "vue";

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
});

const selectedTab = inject("selectedTab");
const setSelectedTab = inject("setSelectedTab");

const isActiveTrigger = ref(true);

const tabRef = ref(null);

async function trigger() {
  if (selectedTab.value === props.value) return;

  isActiveTrigger.value = false;
  setSelectedTab(props.value);
  nextTick(() => updateTabActive());
}

const updateTabActive = () => {
  const tabActive = document.querySelector(".tabActive");
  const activeTab = tabRef.value;

  if (activeTab && tabActive) {
    tabActive.style.width = `${activeTab.offsetWidth}px`;
    tabActive.style.left = `${activeTab.offsetLeft}px`;

    if (tabActive.offsetWidth === 0 && isActiveTrigger.value) {
      tabRef.value.classList.add("active-color");
    } else {
      tabRef.value.classList.remove("active-color");
    }
  }
};

onMounted(() => {
  if (selectedTab.value === props.value) {
    nextTick(() => updateTabActive());
  }
});

watch(selectedTab, (newValue) => {
  if (newValue === props.value) {
    nextTick(() => updateTabActive());
  }
});
</script>

<template>
  <button ref="tabRef" :class="{ active: selectedTab === props.value }" @click="trigger">
    <slot />
  </button>
</template>

<style scoped lang="scss">
button {
  background-color: transparent;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 10px;
  height: 30px;

  border: none;
  border-radius: 24px;

  color: var(--gray05);
  font-weight: 600;
  font-size: 14px;
  text-wrap: nowrap;

  transition: all 0.3s ease;

  &.active {
    color: var(--greenn);
  }

  &.active-color {
    background-color: var(--greenn2);
  }
}
</style>
