import Rest from '@/services/Rest';

/**
 * @typedef {PageService}
 */
export default class PageService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'page'


}