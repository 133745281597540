import Rest from "@/services/Rest";

/**
 * @typedef {AtividadesService}
 */
export default class AtividadesService extends Rest {
  /**
   * @type {String}
   */
  static resource = "category";
}
