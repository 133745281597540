import Rest from '@/services/Rest';

/**
 * @typedef {MidiaService}
 */
export default class MidiaService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'midia'


}