import Rest from '@/services/Rest';

/**
 * @typedef {LeadMessageService}
 */
export default class LeadMessageService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'lead-message'

}