let db;

export function setupDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("gddb", 1);

    request.onerror = function (event) {
      console.error("Erro ao abrir o banco de dados", event);
      reject(event);
    };

    request.onsuccess = function (event) {
      db = event.target.result;
      resolve();
    };

    request.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("apiCache")) {
        db.createObjectStore("apiCache", { keyPath: "url" });
      }
    };
  });
}

export async function getCachedData(url) {
  return new Promise(async (resolve, reject) => {
    await setupDB();
    const transaction = db.transaction(["apiCache"]);
    const objectStore = transaction.objectStore("apiCache");
    const request = objectStore.get(url);
    request.onerror = reject;
    request.onsuccess = async function (event) {
      resolve(event.target.result ? event.target.result.data : null);
    };
  });
}

export function cacheData(url, data) {
  const transaction = db.transaction(["apiCache"], "readwrite");
  const objectStore = transaction.objectStore("apiCache");
  objectStore.put({ url: url, data: data });
}
