f<template>
  <transition name="fade">
    <div v-show="currentModal === 'lead'" class="drop-bottom drop-right edit-lead" @click.stop>
      <section>
        <nav>
          <p @click="setActiveTab('infos')" :class="{ 'no-active': activeTab !== 'infos' }">
            Informações básicas
          </p>
          <p v-if="leadsUnification" @click="setActiveTab('address')" :class="{ 'no-active': activeTab !== 'address' }">
            Endereço
          </p>

          <span
            class="active"
            :class="{
              'active-infos': isActiveTab('infos'),
              'active-address': isActiveTab('address'),
            }"
          />
        </nav>

        <form v-show="isActiveTab('infos')">
          <div>
            <label>Nome</label>
            <input type="text" class="w-100" name="nome" id="nome" v-model="infosForm.name" />
          </div>

          <div>
            <label>Score - {{ infosForm.leadscore }}</label>
            <input type="number" v-model="infosForm.leadscore" />
          </div>

          <div>
            <label>Endereço de e-mail <span class="gray"> - opcional</span></label>
            <input
              :disabled="lead.email !== ''"
              class="w-100"
              type="text"
              name="email"
              id="email"
              v-model="infosForm.email"
            />
          </div>

          <div>
            <div class="label-valid">
              <label>Número de telefone</label>
              <img
                v-if="!isValidNumberPhone && infosForm.phone?.length > 0"
                src="@/assets/icons/icon-error.svg"
              />
            </div>

            <VuePhoneNumberInput
              :translations="translations"
              :default-country-code="defaultCountry"
              :fetch-country="returnFlag(lead.phone)"
              :color="!isValidNumberPhone ? '#d31408' : '#058A4A'"
              :valid-color="!isValidNumberPhone ? '#058A4A' : '#d31408'"
              id="formattedNumber"
              :class="{ 'phone-invalid-lead': !isValidNumberPhone && infosForm.phone?.length > 0 }"
              :max-length="15"
              :only-countries="countriesISO"
              @update="getNumber"
              v-model="infosForm.phone"
            />
          </div>
        </form>

        <form v-show="isActiveTab('address')" class="form-address">
          <div>
            <div class="label-valid">
              <label>CEP</label>
              <img
                v-if="!isValidCep && addressForm.zipCode?.length > 0"
                src="@/assets/icons/icon-error.svg"
              />
            </div>

            <input
              v-mask="'#####-###'"
              type="text"
              v-model="addressForm.zipCode"
              :class="{ 'isInvalid-zipcode': !isValidCep && addressForm.zipCode?.length > 0 }"
              @input="debounceZipCode"
            />
          </div>

          <div>
            <label>Estado</label>
            <input @input="validateFields($event, 'state')" type="text" v-model="addressForm.state" />
          </div>

          <div>
            <label>Cidade</label>
            <input @input="validateFields($event, 'city')" type="text" v-model="addressForm.city" />
          </div>

          <div>
            <label>Logradouro</label>
            <input @input="validateFields($event, 'street')" type="text" v-model="addressForm.street" />
          </div>

          <div>
            <label>Bairro</label>
            <input @input="validateFields($event, 'neighborhood')" ref="neighborhood" type="text" v-model="addressForm.neighborhood" />
          </div>

          <div>
            <label>Número/Bloco</label>
            <input v-model="addressForm.number" />
          </div>
        </form>
      </section>

      <button
        :disabled="!isValidNumberPhone && infosForm.phone?.length > 0"
        @click="save"
        class="btn-follow w-100"
        style="margin-top: 25px"
      >
        Salvar alterações
      </button>
    </div>
  </transition>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, onMounted } from "vue";
import VuePhoneNumberInput from "vue-phone-number-input";

import axios from "axios";
import { useLead } from "@/utils/lead.js";

const { defaultCountry, returnFlag, countriesISO } = useLead();

const emit = defineEmits(["save"]);

const props = defineProps({
  currentModal: String,
  lead: Object,
  address: Object,
  translations: Object,
  leadsUnification: Boolean
});

const isValidCep = ref(true);
const isValidNumberPhone = ref(true);
const phoneFormatted = ref("");

const infosForm = ref({
  name: "",
  leadscore: 0,
  email: "",
  phone: "",
});

const addressForm = ref({
  city: "",
  countryCode: "",
  id: null,
  neighborhood: "",
  number: null,
  state: "",
  street: "",
  zipCode: "",
});

const activeTab = ref("infos");

function setActiveTab(tab) {
  activeTab.value = tab;
}

function isActiveTab(tab) {
  return activeTab.value === tab;
}

const debounceZipCode = _.debounce((event) => {
  const code = event.target.value.replace("-", "");
  if (code.length < 8) return;

  checkZipCode(code);
}, 100);

const checkZipCode = async (zipCode) => {
  const address = await getAddress(zipCode);
  if (address.erro) {
    isValidCep.value = false;
    return;
  }
  isValidCep.value = true;

  addressForm.value.state = address.uf;
  addressForm.value.city = address.localidade;
  addressForm.value.neighborhood = address.bairro;
  addressForm.value.street = address.logradouro;
  addressForm.value.countryCode = address.uf;
};

const getAddress = async (zipCode) => {
  try {
    const { data } = await axios.get(`https://viacep.com.br/ws/${zipCode}/json`);
    return data;
  } catch (error) {
    return { erro: true };
  }
};

function getNumber(phone) {
  isValidNumberPhone.value = phone.isValid;
  phoneFormatted.value = phone?.formattedNumber;
}

function save() {
  if (!isValidNumberPhone.value && infosForm.value.phone?.length > 0) return;

  infosForm.value.phone = phoneFormatted.value;
  emit("save", { infos: infosForm.value, address: addressForm.value });
}

function getData() {
  const { name, leadscore, email, phone, id } = props.lead;
  infosForm.value = {
    name,
    leadscore,
    email,
    phone,
  };

  const {
    city = "",
    country_code = "",
    neighborhood = "",
    number = null,
    state = "",
    street = "",
    zip_code = "",
  } = props.address || {};

  addressForm.value = {
    city,
    countryCode: country_code,
    id: id ? `address/${id}` : null,
    neighborhood,
    number,
    state,
    street,
    zipCode: zip_code,
  };
}

function validateFields(event, property) {
  addressForm.value[property] = event.target.value.replace(/\d/g, '');
}

watch(
  () => [props.lead, props.address],
  ([newLead, newAddress]) => {
      getData();
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
.edit-lead {
  margin-top: 0.75rem;
}

.drop-right {
  right: 0 !important;
  left: unset !important;
  transform: unset;
}
.drop-bottom {
  top: calc(100% + 8px);
}

.btn-follow {
  margin-top: 0;
  font-weight: bold;
  transition: 0.5s ease;
  background: #e5f9f6;
  color: var(--greenn);
  text-transform: uppercase;
  font-size: 12px;
  padding: 8px !important;
  border-radius: 8px;
  border: none;
  height: 40px;
  transition: all 0.3s;
  text-align: center;

  &:disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }
}

.gray {
  color: silver;
  font-size: 14px;
  font-weight: normal;
}

.label-valid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  img {
    margin-bottom: 4px !important;
  }
}

section {
  nav {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    height: 30px;
    margin-bottom: 24px;

    border-bottom: 1.5px solid var(--white-medium);
    position: relative;

    p {
      font-weight: 600;
      font-size: 13px;

      padding: 0 10px;
      color: var(--greenn);

      cursor: pointer;
    }

    .no-active {
      color: var(--gray05) !important;
    }

    .active {
      content: "";
      position: absolute;
      bottom: -1.7px;

      background-color: var(--greenn);
      border-radius: 5px;

      height: 2px;

      transition: all 0.3s;
    }

    .active-infos {
      width: 62%;
      left: 0;
    }

    .active-address {
      width: 32%;
      left: 68%;
    }
  }

  form {
    display: grid;
    gap: 20px 14px;
    animation: show 0.5s both;

    @keyframes show {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
    input {
      width: 100%;
      margin: 0 !important;
    }

    label {
      margin-bottom: 4px !important;
    }
  }

  .form-address {
    grid-template-columns: repeat(3, 1fr);

    div:nth-child(1),
    div:nth-child(5) {
      grid-column: span 2;
    }

    div:nth-child(2),
    div:nth-child(6) {
      grid-column: span 1;
    }

    div:nth-child(3),
    div:nth-child(4) {
      grid-column: span 3;
    }
  }
}
</style>
