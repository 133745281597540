export default {
  data() {
    return {
      defaultCountry: "BR",
      countries: [
        {
          iso: "AF",
          code: "93",
        },
        {
          iso: "AL",
          code: "355",
        },
        {
          iso: "DZ",
          code: "213",
        },
        {
          iso: "AD",
          code: "376",
        },
        {
          iso: "AO",
          code: "244",
        },
        {
          iso: "AR",
          code: "54",
        },
        {
          iso: "AM",
          code: "374",
        },
        {
          iso: "AW",
          code: "297",
        },
        {
          iso: "AU",
          code: "61",
        },
        {
          iso: "AT",
          code: "43",
        },
        {
          iso: "AZ",
          code: "994",
        },
        {
          iso: "BH",
          code: "973",
        },
        {
          iso: "BD",
          code: "880",
        },
        {
          iso: "BY",
          code: "375",
        },
        {
          iso: "BE",
          code: "32",
        },
        {
          iso: "BZ",
          code: "501",
        },
        {
          iso: "BJ",
          code: "229",
        },
        {
          iso: "BT",
          code: "975",
        },
        {
          iso: "BO",
          code: "591",
        },
        {
          iso: "BA",
          code: "387",
        },
        {
          iso: "BW",
          code: "267",
        },
        {
          iso: "BR",
          code: "55",
        },
        {
          iso: "IO",
          code: "246",
        },
        {
          iso: "BN",
          code: "673",
        },
        {
          iso: "BG",
          code: "359",
        },
        {
          iso: "BF",
          code: "226",
        },
        {
          iso: "BI",
          code: "257",
        },
        {
          iso: "KH",
          code: "855",
        },
        {
          iso: "CM",
          code: "237",
        },
        {
          iso: "CV",
          code: "238",
        },
        {
          iso: "CF",
          code: "236",
        },
        {
          iso: "TD",
          code: "235",
        },
        {
          iso: "CL",
          code: "56",
        },
        {
          iso: "CN",
          code: "86",
        },
        {
          iso: "CO",
          code: "57",
        },
        {
          iso: "KM",
          code: "269",
        },
        {
          iso: "CD",
          code: "243",
        },
        {
          iso: "CG",
          code: "242",
        },
        {
          iso: "CK",
          code: "682",
        },
        {
          iso: "CR",
          code: "506",
        },
        {
          iso: "CI",
          code: "225",
        },
        {
          iso: "HR",
          code: "385",
        },
        {
          iso: "CU",
          code: "53",
        },
        {
          iso: "CW",
          code: "599",
        },
        {
          iso: "CY",
          code: "357",
        },
        {
          iso: "CZ",
          code: "420",
        },
        {
          iso: "DK",
          code: "45",
        },
        {
          iso: "DJ",
          code: "253",
        },
        {
          iso: "EC",
          code: "593",
        },
        {
          iso: "EG",
          code: "20",
        },
        {
          iso: "SV",
          code: "503",
        },
        {
          iso: "GQ",
          code: "240",
        },
        {
          iso: "ER",
          code: "291",
        },
        {
          iso: "EE",
          code: "372",
        },
        {
          iso: "SZ",
          code: "268",
        },
        {
          iso: "ET",
          code: "251",
        },
        {
          iso: "FK",
          code: "500",
        },
        {
          iso: "FO",
          code: "298",
        },
        {
          iso: "FJ",
          code: "679",
        },
        {
          iso: "FI",
          code: "358",
        },
        {
          iso: "FR",
          code: "33",
        },
        {
          iso: "GF",
          code: "594",
        },
        {
          iso: "PF",
          code: "689",
        },
        {
          iso: "GA",
          code: "241",
        },
        {
          iso: "GM",
          code: "220",
        },
        {
          iso: "GE",
          code: "995",
        },
        {
          iso: "DE",
          code: "49",
        },
        {
          iso: "GH",
          code: "233",
        },
        {
          iso: "GI",
          code: "350",
        },
        {
          iso: "GR",
          code: "30",
        },
        {
          iso: "GL",
          code: "299",
        },
        {
          iso: "GP",
          code: "590",
        },
        {
          iso: "GT",
          code: "502",
        },
        {
          iso: "GN",
          code: "224",
        },
        {
          iso: "GW",
          code: "245",
        },
        {
          iso: "GY",
          code: "592",
        },
        {
          iso: "HT",
          code: "509",
        },
        {
          iso: "HN",
          code: "504",
        },
        {
          iso: "HK",
          code: "852",
        },
        {
          iso: "HU",
          code: "36",
        },
        {
          iso: "IS",
          code: "354",
        },
        {
          iso: "IN",
          code: "91",
        },
        {
          iso: "ID",
          code: "62",
        },
        {
          iso: "IR",
          code: "98",
        },
        {
          iso: "IQ",
          code: "964",
        },
        {
          iso: "IE",
          code: "353",
        },
        {
          iso: "IL",
          code: "972",
        },
        {
          iso: "IT",
          code: "39",
        },
        {
          iso: "JP",
          code: "81",
        },
        {
          iso: "JO",
          code: "962",
        },
        {
          iso: "KE",
          code: "254",
        },
        {
          iso: "KI",
          code: "686",
        },
        {
          iso: "XK",
          code: "383",
        },
        {
          iso: "KW",
          code: "965",
        },
        {
          iso: "KG",
          code: "996",
        },
        {
          iso: "LA",
          code: "856",
        },
        {
          iso: "LV",
          code: "371",
        },
        {
          iso: "LB",
          code: "961",
        },
        {
          iso: "LS",
          code: "266",
        },
        {
          iso: "LR",
          code: "231",
        },
        {
          iso: "LY",
          code: "218",
        },
        {
          iso: "LI",
          code: "423",
        },
        {
          iso: "LT",
          code: "370",
        },
        {
          iso: "LU",
          code: "352",
        },
        {
          iso: "MO",
          code: "853",
        },
        {
          iso: "MG",
          code: "261",
        },
        {
          iso: "MW",
          code: "265",
        },
        {
          iso: "MY",
          code: "60",
        },
        {
          iso: "MV",
          code: "960",
        },
        {
          iso: "ML",
          code: "223",
        },
        {
          iso: "MT",
          code: "356",
        },
        {
          iso: "MH",
          code: "692",
        },
        {
          iso: "MQ",
          code: "596",
        },
        {
          iso: "MR",
          code: "222",
        },
        {
          iso: "MU",
          code: "230",
        },
        {
          iso: "MX",
          code: "52",
        },
        {
          iso: "FM",
          code: "691",
        },
        {
          iso: "MD",
          code: "373",
        },
        {
          iso: "MC",
          code: "377",
        },
        {
          iso: "MN",
          code: "976",
        },
        {
          iso: "ME",
          code: "382",
        },
        {
          iso: "MA",
          code: "212",
        },
        {
          iso: "MZ",
          code: "258",
        },
        {
          iso: "MM",
          code: "95",
        },
        {
          iso: "NA",
          code: "264",
        },
        {
          iso: "NR",
          code: "674",
        },
        {
          iso: "NP",
          code: "977",
        },
        {
          iso: "NL",
          code: "31",
        },
        {
          iso: "NC",
          code: "687",
        },
        {
          iso: "NZ",
          code: "64",
        },
        {
          iso: "NI",
          code: "505",
        },
        {
          iso: "NE",
          code: "227",
        },
        {
          iso: "NG",
          code: "234",
        },
        {
          iso: "NU",
          code: "683",
        },
        {
          iso: "NF",
          code: "672",
        },
        {
          iso: "KP",
          code: "850",
        },
        {
          iso: "MK",
          code: "389",
        },
        {
          iso: "NO",
          code: "47",
        },
        {
          iso: "OM",
          code: "968",
        },
        {
          iso: "PK",
          code: "92",
        },
        {
          iso: "PW",
          code: "680",
        },
        {
          iso: "PS",
          code: "970",
        },
        {
          iso: "PA",
          code: "507",
        },
        {
          iso: "PG",
          code: "675",
        },
        {
          iso: "PY",
          code: "595",
        },
        {
          iso: "PE",
          code: "51",
        },
        {
          iso: "PH",
          code: "63",
        },
        {
          iso: "PL",
          code: "48",
        },
        {
          iso: "PT",
          code: "351",
        },
        {
          iso: "QA",
          code: "974",
        },
        {
          iso: "RE",
          code: "262",
        },
        {
          iso: "RO",
          code: "40",
        },
        {
          iso: "RU",
          code: "7",
        },
        {
          iso: "RW",
          code: "250",
        },
        {
          iso: "SH",
          code: "290",
        },
        {
          iso: "PM",
          code: "508",
        },
        {
          iso: "WS",
          code: "685",
        },
        {
          iso: "SM",
          code: "378",
        },
        {
          iso: "ST",
          code: "239",
        },
        {
          iso: "SA",
          code: "966",
        },
        {
          iso: "SN",
          code: "221",
        },
        {
          iso: "RS",
          code: "381",
        },
        {
          iso: "SC",
          code: "248",
        },
        {
          iso: "SL",
          code: "232",
        },
        {
          iso: "SG",
          code: "65",
        },
        {
          iso: "SK",
          code: "421",
        },
        {
          iso: "SI",
          code: "386",
        },
        {
          iso: "SB",
          code: "677",
        },
        {
          iso: "SO",
          code: "252",
        },
        {
          iso: "ZA",
          code: "27",
        },
        {
          iso: "KR",
          code: "82",
        },
        {
          iso: "SS",
          code: "211",
        },
        {
          iso: "ES",
          code: "34",
        },
        {
          iso: "LK",
          code: "94",
        },
        {
          iso: "SD",
          code: "249",
        },
        {
          iso: "SR",
          code: "597",
        },
        {
          iso: "SE",
          code: "46",
        },
        {
          iso: "CH",
          code: "41",
        },
        {
          iso: "SY",
          code: "963",
        },
        {
          iso: "TW",
          code: "886",
        },
        {
          iso: "TJ",
          code: "992",
        },
        {
          iso: "TZ",
          code: "255",
        },
        {
          iso: "TH",
          code: "66",
        },
        {
          iso: "TL",
          code: "670",
        },
        {
          iso: "TG",
          code: "228",
        },
        {
          iso: "TK",
          code: "690",
        },
        {
          iso: "TO",
          code: "676",
        },
        {
          iso: "TN",
          code: "216",
        },
        {
          iso: "TR",
          code: "90",
        },
        {
          iso: "TM",
          code: "993",
        },
        {
          iso: "TV",
          code: "688",
        },
        {
          iso: "UG",
          code: "256",
        },
        {
          iso: "UA",
          code: "380",
        },
        {
          iso: "AE",
          code: "971",
        },
        {
          iso: "GB",
          code: "44",
        },
        {
          iso: "US",
          code: "1",
        },
        {
          iso: "UY",
          code: "598",
        },
        {
          iso: "UZ",
          code: "998",
        },
        {
          iso: "VU",
          code: "678",
        },
        {
          iso: "VE",
          code: "58",
        },
        {
          iso: "VN",
          code: "84",
        },
        {
          iso: "WF",
          code: "681",
        },
        {
          iso: "YE",
          code: "967",
        },
        {
          iso: "ZM",
          code: "260",
        },
        {
          iso: "ZW",
          code: "263",
        },
      ],
      countriesISO: [
        "BR", "FR", "PT", "US", "ES", "JP", "GB", "DE", "IT", "CN", "AR", "PY", "AF", "AL", "DZ", "AD", "AO", "AM", "AW", "AU", "AT", "AZ", "BH", "BD", "BY", "BE", "BZ", "BJ", "BT", "BO", "BA", "BW", "IO", "BN", "BG", "BF", "BI", "KH", "CM", "CV", "CF", "TD", "CL", "KM", "CD", "CG", "CK", "CR", "CI", "HR", "CU", "CW", "CY", "CZ", "DK", "DJ", "EC", "EG", "SV", "GQ", "ER", "EE", "SZ", "ET", "FK", "FO", "FJ", "FI", "GF", "PF", "GA", "GM", "GE", "GH", "GI", "GR", "GL", "GP", "GT", "GN", "GW", "GY", "HT", "HN", "HK", "HU", "IS", "IN", "ID", "IR", "IQ", "IE", "IL", "JO", "KE", "KI", "XK", "KW", "KG", "LA", "LV", "LB", "LS", "LR", "LY", "LI", "LT", "LU", "MO", "MG", "MW", "MY", "MV", "ML", "MT", "MH", "MQ", "MR", "MU", "FM", "MD", "MC", "MN", "ME", "MA", "MZ", "MM", "NA", "NR", "NP", "NL", "NC", "NZ", "NI", "NE", "NG", "NU", "NF", "KP", "MK", "NO", "OM", "PK", "PW", "PS", "PA", "PG", "PH", "PL", "QA", "RE", "RO", "RU", "RW", "SH", "PM", "WS", "SM", "ST", "SA", "SN", "RS", "SC", "SL", "SG", "SK", "SI", "SB", "SO", "ZA", "KR", "SS", "LK", "SD", "SR", "SE", "CH", "SY", "TW", "TJ", "TZ", "TH", "TL", "TG", "TK", "TO", "TN", "TR", "TM", "TV", "UG", "UA", "AE", "UY", "UZ", "VU", "VE", "VN", "WF", "YE", "ZM", "ZW"
      ]
    };
  },
  methods: {
    returnFlag(phone) {
      if (!phone) {
        return
      }
      let defaultCountry = null;

      for (const country of this.countries) {
        const countryCodePattern = new RegExp(`^\\+${country.code}`, "g");
        if (phone.match(countryCodePattern)) {
          defaultCountry = country.iso;
          break;
        }
      }

      if (defaultCountry) {
        this.defaultCountry = defaultCountry
      }
    },
  },
};
