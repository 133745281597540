import Rest from "@/services/Rest";

/**
 * @typedef {CompanyService}
 */
export default class CompanyService extends Rest {
  /**
   * @type {String}
   */
  static resource = "company";
}
