import Rest from '@/services/Rest';

/**
 * @typedef {WhatsappService}
 */
export default class WhatsappService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'wpp'


}